:root {
  --st-modal-transformHidden: translate3d(-78%, 100%, 0) scale(0.5) rotateX(-90deg) !important;
  --toastify-color-success: var(--color-success-background);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media (min-width: 767px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1023px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1365px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 1599px) {
  html {
    font-size: 16px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rdrStaticRanges {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
}
.rdrDefinedRangesWrapper {
  width: 100% !important;
}
.rdrInputRanges {
  padding: 0 !important;
}
.rdrInRange,
.rdrDay,
.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrStartEdge,
.rdrEndEdge {
  @apply text-primaryDark !important;
}
input {
  border: 1px solid #e5e7eb;
}

.prev-next-btn:disabled {
  visibility: hidden;
}

.Modal-module_modalButtonPlace__AYeOm > button {
  border-radius: 0;
}

.Modal-module_modalButtonPlace__AYeOm > button:focus {
  outline: none !important;
}

.Button-module_button__3FnXy {
  padding: 0.8rem 2rem !important;
}

.Modal-module_modalButtonPlace__AYeOm {
  background-color: white !important;
  border-top: 2px solid aliceblue;
}

.request-feedback-modal {
  max-width: 70% !important;
  border-radius: 16px !important;
}

.request-feedback-modal > div {
  overflow-y: auto !important;
}

.log-session-modal {
  border-radius: 3% !important;
}

.profile-modal {
  max-width: 50% !important;
}

/* Tooltip */
.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

/* Ripple effect */
/* .ripple {
  background-position: center;
  transition: background 0s;
}
.ripple:hover {
  background: radial-gradient(circle, #e4e8fc00 1%, #dfdfdf11 1%) center/15000%;
}
.ripple:active {
  background-color: #e4e8fc00;
  background-size: 100%;
  transition: background 0s;
} */

.hrtable > div:nth-child(odd),
.hrtable > tr:nth-child(odd) {
  background-color: #fff;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(216, 73, 73, 0.2); */
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

/* Pagination */
.Pagination .disabled {
  opacity: 0.2;
  pointer-events: none;
}

.Pagination .active {
  opacity: 1;
  background-color: var(--color-accent);
  color: #fff;
  border-radius: 100%;
}

.knack-bg-green {
  background-color: var(--knack_primary_green);
}

.knack-text-muted {
  color: var(--knack-muted);
}

.knack-content-bg {
  background: var(--knack-content-bg);
}

.knack-bg-primary {
  background-color: var(--color-accent) !important;
  color: #fff !important;
}

.knack-bg-secondary {
  background-color: var(--knack-secondary) !important;
  color: #fff;
}

.knack-text-secondary {
  color: var(--knack-secondary) !important;
}

.knack-bg-light {
  background-color: var(--knack_light) !important;
  color: var(--knack_dark) !important;
}

.knack-bg-primary-light {
  background-color: var(--knack_primary_light) !important;
}

.knack-bg-green {
  background-color: var(--knac_green);
}

.knack-bg-darkblue {
  background-color: var(--knack_dark);
}

.knack-text-dark {
  color: var(--knack_dark) !important;
}

.knack-text-gray {
  color: var(--knack_gray) !important;
}

.knack-text-green {
  color: #00ca69 !important;
}

.knack-fill-primary {
  color: var(--color-accent) !important;
}

.knack-border-primary {
  border-color: rgba(31, 64, 230, 0.418) !important;
}

.knack-border-primary-full {
  border: 2px solid;
  border-color: rgb(31, 64, 230) !important;
}

.knack-border-primary-half {
  border: 1px solid rgb(31, 64, 230) !important;
}

.knack-border-bottom-primary {
  border-bottom: 2px solid var(--color-accent) !important;
}

.knack-border-green {
  border-color: #00ca68a4 !important;
}

.knack textarea,
.knack input {
  outline: gray;
  border: 1px solid gray;
}

.knack input:focus,
.knack textarea:focus {
  outline: none;
  border: 2px solid rgb(31, 64, 230) !important;
}

.dateTime_Program .react-date-picker__inputGroup {
  display: flex;
  flex-direction: row;
}

.knack-tagInput::placeholder {
  white-space: pre-wrap;
  font-size: small;
  padding: 0;
}

.knack-tagInput:-ms-input-placeholder {
  white-space: pre-wrap;
  font-size: small;
  padding: 0;
}

.knack-tagInput::-ms-input-placeholder {
  white-space: pre-wrap;
  font-size: small;

  padding: 0;
}

.restrict-grid-text {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

/* profile dropdown */
#profileDropDown:hover #profileDropdownContent {
  display: block;
}

#profileDropdownContent {
  transform: translate(10px, -8px);
  display: none;
}

/* Status Label */
.Pending {
  color: #9ea6b3;
  background-color: #edeef2;
}

.Published {
  color: #278663;
  background-color: #aaf4d1;
}

#InputSelectDropdownContent {
  display: none;
  transform: translate(0px, -6px);
}

.InputSelectDropdown:hover #InputSelectDropdownContent {
  display: block;
}

.knack_option_menu_custom_items a {
  border-bottom: 1px solid #eee;
  display: block;
}

.knack_option_menu_custom_items a:last-child {
  border: none;
}

.remove_margin_padding_top {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.scrollhost::-webkit-scrollbar {
  width: 10px;
  border-radius: 7px;
  background-color: rgb(0 0 0 / 0%);
}

.scrollhost::-webkit-scrollbar-track {
  background: rgb(245 0 87 / 0%);
}

.scrollhost::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: #f1f1f1ec;
}

.scrollhost2::-webkit-scrollbar {
  width: 8px;
  border-radius: 16px;
  background-color: rgb(0 0 0 / 0%);
}
.scrollhost2::-webkit-scrollbar-track {
  background: var(--color-muted);
  border-radius: 60px;
}
.scrollhost2::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: var(--color-accent);
}

.rbc-time-content::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}

.rbc-time-content::-webkit-scrollbar-track {
  background: rgb(245 0 87 / 0%);
}

.rbc-time-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  min-height: 40px;
  background: #dadce0;
  box-shadow: none;
}

@media (max-width: 800px) {
  .removeScrollhost::-webkit-scrollbar {
    width: 0 !important;
  }
}

@media (max-width: 768px) {
  .ReactApexChart_height_Resolve {
    min-height: 400px;
    height: 100%;
    max-height: 400px;
  }
}

@media (min-width: 768px) {
  .ReactApexChart_height_Resolve {
    min-height: 500px;
    max-height: 580px;
    height: 100%;
  }
}

@media (min-width: 1280px) {
  .ReactApexChart_height_Resolve {
    min-height: 400px;
    max-height: 473px;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .ReactApexChart_height_Resolve2 {
    height: 400px;
    min-height: 400px;
  }
}

@media (min-width: 768px) {
  .ReactApexChart_height_Resolve2 {
    height: 230px;
  }
}

@media (min-width: 1280px) {
  .ReactApexChart_height_Resolve2 {
    height: 400px;
  }
}

.flippedScrollBar::-webkit-scrollbar {
  width: 10px;
  border-radius: 7px;
  background-color: #2563eb1c;
}

.flippedScrollBar::-webkit-scrollbar-track {
  background: rgb(245 0 87 / 0%);
}
.flippedScrollBar::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: #2563eb;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.ReactApexChart_height_Resolve_Coaching_Pie {
  flex: 1 !important;
  height: 100% !important;
  min-height: 600px;
}

/* .ReactApexChart_height_Resolve_Coaching_Pie svg,
.ReactApexChart_height_Resolve_Coaching_Pie foreignObject {
  flex: 1 1 !important;
  height: 500px !important;
}

.ReactApexChart_height_Resolve_Coaching_Pie .microL_pieWrapper > div {
  flex: 1 1 !important;
  height: 500px !important;
}

.ReactApexChart_height_Resolve_Coaching_Pie .microL_pieWrapper .apexcharts-canvas {
  flex: 1 1 !important;
  height: 500px !important;
}

.ReactApexChart_height_Resolve_Coaching_Pie .microL_pieWrapper .apexcharts-canvas foreignObject {
  flex: 1 1 !important;
  height: 500px !important;
} */

/* @media (max-width: 768px) {
  .ReactApexChart_height_Resolve_Coaching_Pie {
    min-height: 400px;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .ReactApexChart_height_Resolve_Coaching_Pie {
    min-height: 500px;
    height: 100%;
  }
}

@media (min-width: 1280px) {
  .ReactApexChart_height_Resolve_Coaching_Pie {
    min-height: 400px;
    height: 100%;
  }
} */

.evenOddBorder > div:nth-child(1) {
  padding-top: 0;
}

.evenOddBorder > div {
  border-bottom: 1px solid #eee;
}

.evenOddBorder > div:last-child {
  border-bottom: 0;
}

.button_hover_turn_white:hover {
  border: 2px solid var(--color-accent);
  background-color: #fff !important;
}

.button_hover_turn_white:hover svg {
  fill: var(--color-accent);
}

.el-picker-panel,
.el-date-picker {
  z-index: 999999999999 !important;
}

.addSessionDrawer .el-input__inner {
  border: none !important;
  outline: none !important;
  --tw-ring-color: #fff;
}

.addSessionDrawer .el-date-editor,
.addSessionDrawer .el-input,
.addSessionDrawer .el-input__inner {
  width: 100% !important;
}

@media (max-width: 768px) {
  .addSessionDrawer .el-date-picker {
    min-width: 407px !important;
  }
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: inherit;
  max-height: inherit;
}

.figure-pie-chart {
  position: relative;
}

.circle {
  fill: none;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.assessmentHover:hover {
  background-color: var(--knack_primary_light);
  border: 1px solid var(--color-accent);
}

.someHover:hover {
  background-color: var(--knack_primary_light);
}

.assessmentSelected {
  background-color: var(--knack_primary_light);
  border: 1px solid var(--color-accent);
}

@media (max-width: 700px) {
  .team_Assessment {
    max-width: calc(94vw - 0rem);
  }
}

.removeRing input:focus {
  --tw-ring-color: "transparent" !important;
}

.break-words {
  word-break: break-word;
}

.bg-notification {
  background-color: #fff !important;
  border: 1px solid var(--knack_primary_light);
  border-radius: 0.5rem !important;
}

.bg-notification svg {
  fill: #000 !important;
}

.knack-toast-notification {
  background-color: #fff !important;
  --tw-border-opacity: 0.3;
  border-radius: 0.5rem !important;
}

.reformatChatTooltip {
  display: flex;
  flex-direction: column;
  padding: 0.5rem !important;
  font-size: 12px;
}

.removeInputRing:focus {
  --tw-ring-shadow: none !important;
}

.Modal-module_overlay__21YAk {
  z-index: 99999999 !important;
}

.CreateTaskCustomStyle {
  top: 20% !important;
  border-radius: 16px !important;
}

.SessionRatingModal {
  top: 50% !important;
  border-radius: 16px !important;
  max-width: 345px !important;
}

@media (max-width: 500px) {
  .SessionRatingModal {
    top: 50% !important;
  }
}

.CalendarPanel .react-calendar {
  border: none;
}

.CalendarPanel abbr {
  text-decoration: none !important;
  font-size: 12px;
  color: var(--knack-darkblue-text);
}

.CalendarPanel .react-calendar__month-view__days__day--weekend {
  color: var(--knack-darkblue-text);
}

.CalendarPanel .react-calendar__tile--active {
  border-radius: 4px;
}

.CalendarPanel span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-weight: 600;
  font-size: 14px;
}

.clampText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-text-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.clamp-text-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.fade-enter .btn {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .btn {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .btn {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .btn {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .btn,
.fade-exit-active .btn {
  transition: opacity 500ms, transform 500ms;
}

.bottom-nav-shadow {
  box-shadow: -1px 20px 20px 20px #1f29370d, 0px 4px 20px #1f29370d;
}

.remove-margin > svg {
  margin: 0;
}

.Modal-module_modalBody__3_RoW {
  overflow-y: visible !important;
}

.Modal-module_modal__FqeFw {
  overflow: initial !important;
}

.Modal-module_modalBody__3_RoW {
  overflow-x: hidden !important;
  border-radius: 12px !important;
}

.css-yk16xz-control {
  border-radius: 0.5rem !important;
}

.createProgramDialogStyle {
  height: 100% !important;
  width: 100% !important;
  max-width: 100vw !important;
}

@media (max-width: 800px) {
  .createScheduleModal {
    height: 100% !important;
    width: 100% !important;
    max-width: 100vw !important;
    border-radius: 0 !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle::before {
  display: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #fff !important;
  border: none !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: rgb(31 64 230 / 26%) !important;
  border-radius: 100% !important;
  color: var(--color-accent);
}

.react-datepicker__day--selected {
  /* background-color: var(--knack_dark) !important;
  color: #fff !important; */
  border-radius: 100% !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: var(--knack_dark);
  font-weight: 600;
}

.react-datepicker {
  border: 1px solid #ddd !important;
  overflow: hidden;
  border-radius: 16px;

  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.rdrDateDisplayItem {
  border-width: 2px;
}

.remove-shadow .react-datepicker {
  box-shadow: none !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #1f40e654;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  background-color: var(--color-accent) !important;
  border-radius: 100% !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 100% !important;
}

.placeholder-text-sm::placeholder {
  font-size: 13px;
}

.modify-program-select .css-yk16xz-control,
.modify-program-select .css-1pahdxg-control {
  border-radius: 16rem !important;
}

.modify-program-select .css-1okebmr-indicatorSeparator {
  display: none !important;
}

.react-datepicker {
  border: none !important;
}

/* React Big Calendar CSS start here */

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999;
}
.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header * {
  color: var(--knack_dark);
  border-bottom: none !important;
}
.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: none !important;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}
.rbc-today {
  background-color: #fff;
}
.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  /* background-color: #3174ad; */
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-background-event.rbc-selected {
  background-color: #265985;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important;
  background: transparent !important;
}
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-row {
  display: flex;
  flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
}
.rbc-month-view {
  border: none !important;
  position: relative;
  /* border: 1px solid #ddd; */
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.rbc-month-header {
  display: flex;
  flex-direction: row;
}
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}
.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}
.rbc-day-bg {
  flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  /* border: 1px solid #ddd; */
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}
.rbc-label {
  font-size: 10px;
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  /* border-right: 1px solid #ddd; */
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-slot {
  flex: 1 0 0;
  display: flex;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.rbc-slot-selecting {
  cursor: move;
}
.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  /* border: 1px solid #ddd; */
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}
.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  /* border-top: 2px solid #ddd; */
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  margin-left: 0px;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

.ttb {
  font-family: "Roboto", sans-serif;
  text-align: center;
  height: 100%;
}

.ttb .rbc-event.rbc-event {
  background-color: rgb(98, 77, 246);
  border-color: rgb(98, 77, 246);
  box-shadow: 0.2px 0.2px 2px 0px;
}
.ttb .rbc-current-time-indicator.rbc-current-time-indicator {
  height: 2px;
  background-color: #ea4335;
}

.ttb .rbc-event-label {
  display: none;
}
.ttb .short .event-title {
  font-weight: 500;
  font-size: 11px;
}

.ttb .long .event-title {
  font-weight: 500;
  font-size: 12px;
}

.ttb .long .event-hours {
  font-size: 12px;
  font-weight: 400;
}

.ttb .short .event-hours {
  font-size: 11px;
  font-weight: 500;
}

.ttb .rbc-time-gutter .rbc-timeslot-group:not(:first-child) .rbc-time-slot {
  margin-top: -7px;
}
.ttb .rbc-time-view {
  border: none;
}
.ttb .rbc-events-container {
  border: none;
}

.ttb .rbc-today {
  background-color: unset;
}
.ttb .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 0px;
}
.rbc-day-slot.rbc-time-column.rbc-now.rbc-today .rbc-timeslot-group {
  border-left: none;
}

.rbc-day-slot .rbc-timeslot-group .rbc-time-slot {
  border-top: none;
}
.rbc-time-slot .rbc-label {
  font-size: 10px;
  color: #3c4043;
}

.rbc-time-view,
.rbc-time-content {
  border: none !important;
}

.rbc-today {
  background: transparent !important;
}

.rbc-allday-cell {
  display: none !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-time-view .rbc-header {
  border: none !important;
}

.rbc-time-header-content {
  border: none !important;
}
.rbc-time-header.rbc-overflowing {
  border: none !important;
  margin-right: 0 !important;
}

.rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
  border-left: 1px solid #ddd;
}

.rbc-month-view {
  border-top: none !important;
  border-left: 1px solid;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: transparent;
  border: none !important;
}

.rbc-row-segment > div {
  border-radius: 8px;
}
/* React Big Calendar CSS ends here  */

.css-b8ldur-Input > div > input {
  width: 100% !important;
  max-width: 100% !important;
  outline: none !important;
  border: none !important;
}

.css-b8ldur-Input > div > input:focus {
  outline: none !important;
  border: none !important;
  --tw-ring-color: #fff;
  --tw-ring-shadow: none !important;
}

.createScheduleModal .Select {
  border: none !important;
  box-shadow: none !important;
  color: var(--knack_dark);
}

.FeedbackFormStaticDialog {
  top: 40% !important;
  border-radius: 16px !important;
}

.AdminSelectCompany .Select.Company__control.css-yk16xz-control {
  border-radius: 0 !important;
  border: none;
}

.force-remove-shadow input {
  box-shadow: none !important;
}

.knack-bottom-drawer {
  bottom: 0px !important;
  top: initial !important;
}

@media (max-width: 500px) {
  .knack-bottom-drawer {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.animate-question {
  animation: slidein 1s;
}

.animate-question-card {
  animation: slidein 1s;
}

.animate-slide-in {
  animation: slidein 1s;
}

.animate-slide-out {
  animation: slideOut 1s;
}

@keyframes slidein {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 100;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0%);
    opacity: 100;
  }

  100% {
    transform: translateX(-1000%);
    opacity: 0;
  }
}

#headlessui-dialog-1 {
  z-index: 999999999999999;
}

#SvgjsG2369 text {
  fill: var(--color-text-base) !important;
  font-weight: 700;
}

.fittedText {
  font-size: inherit;
}

.__floater,
.__floater__open,
.react-joyride__overlay {
  z-index: 9999999999 !important;
}

tr:hover .floating-button {
  display: flex !important;
}

@media (max-width: 800px) {
  .todo_list_height_control {
    max-height: 65vh;
  }
}

/* Animation for Share result modal */
.animated-bar-chart div:nth-child(1) {
  animation: height-up-sllightly-down 4s linear;
  height: inherit;
  max-height: 30%;
}

.animated-bar-chart div:nth-child(2) {
  animation: height-up-sllightly-down 6s linear;
  height: inherit;
  max-height: 50%;
}

.animated-bar-chart div:nth-child(3) {
  animation: height-up-sllightly-down 8s linear;
  height: inherit;
  max-height: 70%;
}

.animated-bar-chart div:nth-child(4) {
  animation: height-up-sllightly-down 10s linear;
  height: inherit;
  max-height: 90%;
}

@keyframes height-up-sllightly-down {
  0% {
    height: 10%;
  }

  100% {
    height: 100%;
  }
}

/* Custom Switch Button */
.custom-switch-button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.custom-switch-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-switch-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .custom-switch-slider {
  background-color: var(--color-accent);
}

input:focus + .custom-switch-slider {
  box-shadow: 0 0 1px var(--color-accent);
}

input:checked + .custom-switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.custom-switch-slider.round {
  border-radius: 34px;
}

.custom-switch-slider.round:before {
  border-radius: 50%;
}

#cronofy-availability-rules .schedule {
  margin-bottom: 0px;
}
.schedule .schedule__wrapper {
  height: 65vh;
  overflow-y: auto;
  max-height: 65vh;
  padding-top: 1.5vh;
}

.schedule .schedule__wrapper::-webkit-scrollbar {
  display: none;
}
.schedule .schedule__grid {
  height: 65vh;
}

.schedule .schedule__calendars {
  margin-bottom: 0px;
}

.schedule .schedule__label-row {
  align-items: center;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.schedule .spacer {
  height: 1px;
}

.schedule .schedule__label--time,
.schedule .schedule__slot-background,
.schedule .schedule__tick-mark-wrapper,
.schedule .schedule__slot {
  height: 2.2vh;
}

.schedule .schedule__label--day {
  line-height: 2.5vh;
  height: 2.5vh;
}

.schedule .schedule__time-expander-button {
  position: relative;
}

.schedule .schedule__time-expander-button.schedule__time-expander-button--backwards {
  margin-bottom: -5.5vh;
}

.schedule .schedule__time-expander-button.schedule__time-expander-button--forwards {
  margin-top: -5.5vh;
}

.schedule .schedule__submit {
  display: block;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgba(var(--color-fill));
}

@media (max-width: 500px) {
  .force-wrap-text {
    white-space: pre-line !important;
  }
}

.react-chart-label-wrap * {
  white-space: pre-wrap;
  word-break: break-all;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.input-field-with-showhide > div:first-child {
  width: 100%;
}

.session-pivit-datepicker * {
  --tw-shadow: none !important;
}

.nice-shadow {
  -webkit-box-shadow: -5px 9px 14px -20px rgb(66 68 90);
  -moz-box-shadow: -5px 9px 14px -20px rgb(66 68 90);
  box-shadow: -5px 9px 14px -20px rgb(66 68 90);
}

.list-light-border > :not(:last-child) {
  border-bottom: 1px solid #eeeeee9c;
}

.react-datepicker__time-container {
  width: 78px;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc !important;
}

.glare-up-background-animation {
  border-color: var(--color-accent);
  background-image: -webkit-linear-gradient(45deg, var(--color-accent) 50%, transparent 50%);
  background-image: linear-gradient(45deg, var(--color-accent) 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.glare-up-background-animation:hover {
  background-position: 0;
}

.DTP {
  max-width: 1120px;
}

.DTP .DTP__calendar-header-button:hover {
  border-radius: 100%;
}

.DTP .DTP__calendar-header-button {
  border-radius: 100%;
  width: 40px !important;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.DTP .DTP__calendar-header-button--icon {
  width: 1em !important;
  height: 1em !important;
}

.DTP .DTP__column--right {
  flex: 1 1 10em !important;
}

.DTP .DTP__time-slots-list--header {
  font-size: 1em !important;
}

.conferencing-item {
  --tw-border-opacity: 0.6;
  background-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.conferencing-item input::placeholder {
  font-weight: 600;
}

.link-preview .Image {
  background-size: auto !important;
}

button.DTP__time-slot.DTP__time-slot {
  font-size: 0.95em;
}

code {
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 0.9em !important;
  padding: 6px;
}

.fit-w-h {
  width: fit-content;
  height: fit-content;
}

.html-parser ol,
.html-parser ul {
  list-style: auto !important;
  padding: revert;
  margin: revert;
}

.white-space-break-spaces {
  white-space: initial !important;
}

.inner-inner-box-shadow-none input {
  box-shadow: none !important;
}

.select-date ::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>');
}

.select-time ::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="1" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg>');
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type="time"]::-webkit-clear-button {
  display: none;
}
