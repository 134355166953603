@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Product-sans"; /*Can be any text*/
  src: local("ProductSans"), url("./fonts/ProductSans-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Product-sans"; /*Can be any text*/
  src: local("ProductSans"), url("./fonts/ProductSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Product-sans"; /*Can be any text*/
  src: local("ProductSans"), url("./fonts/ProductSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Product-sans"; /*Can be any text*/
  src: local("ProductSans"), url("./fonts/ProductSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Product-sans"; /*Can be any text*/
  src: local("ProductSans"), url("./fonts/ProductSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Product-sans"; /*Can be any text*/
  src: local("ProductSans"), url("./fonts/ProductSans-Thin.ttf") format("truetype");
  font-weight: 200;
}

@layer base {
  :root {
    --color-accent: #2c4be7; /* primary color or accent **/
    /* --table-header: 230, 230, 230; */
    --color-text-base: #2d3958;
    --color-base: #2d3958;
    --color-text-muted: #9ca3af;
    --color-text-inverted: #2c4be7;
    --color-fill: 44, 75, 231;
    --color-fill-dark: #060c21;
    --color-fill-dark-inverted: #1c2234;

    --color-fill-near-full: rgba(var(--color-fill), 0.8);

    --color-fill-semi-light: rgba(var(--color-fill), 0.6);

    --color-fill-light: rgba(var(--color-fill), 0.1);

    --color-fill-lighter: rgba(var(--color-fill), 0.02);

    --color-button-accent: #2c4be7;
    --success-color: 1, 160, 133;
    --success-text-color: #fff;
    --warning-color: 255, 133, 82;
    --warning-text-color: #fff;
    --color-button-accent-hover: #6279ec;
    --color-button-muted: #9ca3af;

    --color-muted: #9ca3af;

    /* --color-body-fill-light: #fafafa; */
    /* --color-body-fill-dark: #000; */

    --color-body-fill-green: #00a085;

    --color-green: #00a085;

    --color-yellow: #f59e0b;
    --color-red: #ef4444;

    --color-dark: #2d3958;

    /* Resolve for --buttonHoverText instead of --buttonTextHover */
    --buttonHoverText: #fff;
    --knack-color: 31, 64, 230;
    /* --knack_primary: rgb(31, 64, 230);
  --knack_primary_light: rgb(228, 232, 252);
  --knack_dark: #2d3958;
  --knack_gray: rgb(123, 123, 123);
  --knack_light: #e5e5e5;
  --knac_green: #00ca69;
  --knack-secondary: #6077e9;
  --knack-content-bg: #fafafa;
  --knack-muted: rgba(45, 57, 88, 0.52);
  --knack_primary_green: #00a085; */

    --st-dialog-maxWidth: 700px !important;
  }

  .notification-menu-items-wrapper {
    @apply overflow-y-auto;
    max-height: 60vh;
  }

  .sessions-list__card--container {
    @apply grid items-center flex-1 grid-cols-1 gap-4 p-6 transition-colors duration-150 cursor-pointer pe-12 hover:bg-gray-100;
    @screen lg {
      grid-template-columns: 1fr auto;
    }
  }
  input[type="text"]:focus {
    box-shadow: none !important;
  }
  .action-card {
    @apply relative rounded-lg;
    padding: 24px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@layer utilities {
  .dialog-content-max-h {
    max-block-size: 100%;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -ms-overflow-style: scrollbar;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .dialog-max-h {
    max-block-size: 85vh;
    max-height: 85vh;
  }

  .filters-bar__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
  }
}
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(var(--tertiary-color));
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(var(--tertiary-color));
  border-radius: 10px;
}

.DTP .DTP__confirm-button {
  color: #fff !important;
}
.font-cairo {
  font-family: "Cairo", sans-serif !important;
}
html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;

  /* font-family: "DM Sans", sans-serif; */
  /* font-family: -apple-system, "Inter", sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Product-sans", "Inter", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", */
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  isolation: isolate;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: "DM Sans", sans-serif;
}

.font-open {
  font-family: "DM Sans", sans-serif;
}
/* .font-open {
  font-family: "Inter", sans-serif;
} */

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

.Modal-module_dialog__2UBIn {
  max-width: var(--st-dialog-maxWidth) !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.questionCount {
  /* padding: 1.5rem 2.5rem 0; */
  font-size: 14px;
}

/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}

/* AnswerOption Component */
.answerOption {
  border-top: 1px solid #eee;
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 5rem;
  font-size: 16px;
  line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: "";
  background: blue url(./icon-check.svg) no-repeat;
  background-size: 27px;
  border-color: blue;
}
[type="radio"]:checked,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
  background-color: var(--color-accent);
}
/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* Star Rating */
.star {
  color: #3b82f6;
  cursor: pointer;
  font-size: 40px;
  vertical-align: middle;
}

.se-dialog.sun-editor-common {
  position: absolute !important;
}

.PhoneInputInput {
  border-radius: 0.5rem;
}

.custom-react-calendar .react-calendar {
  width: 100% !important;
  border: none !important;
  outline: none !important;
}

.react-calendar__tile--now {
  background: var(--color-accent) !important;
  font-weight: 900;
  color: #fff;
}

.custom-react-calendar button {
  border-radius: 8px;
  /* height: 50px; */
}

abbr[title] {
  text-decoration: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none !important;
}

@media (min-width: 799px) {
  .DTP__time-slots-group--wrapper.DTP__time-slots-group--wrapper {
    height: 440px;
    overflow-y: auto;
    padding-right: 8px;
  }

  .DTP__time-slots-group--wrapper.DTP__time-slots-group--wrapper::-webkit-scrollbar {
    width: 4px;
    border-radius: 16px;
    background-color: rgb(0 0 0 / 0%);
  }
  .DTP__time-slots-group--wrapper.DTP__time-slots-group--wrapper::-webkit-scrollbar-track {
    background: var(--color-muted);
    border-radius: 60px;
  }
  .DTP__time-slots-group--wrapper.DTP__time-slots-group--wrapper::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: var(--color-accent);
  }
}

@media (max-width: 800px) {
  .DTP__time-slots-group--wrapper.DTP__time-slots-group--wrapper {
    height: 100%;
  }
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}
/* input:focus {
  outline: none;
  border: none;
} */
.session-logging-datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none !important;
}

.session-logging-datepicker .react-datepicker-popper {
  border-radius: 16px;
  padding-bottom: 0 !important;
}
